import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Container, OverlayTrigger, Tooltip, Badge, Form } from 'react-bootstrap'
import Header from './Header'
import { getBurgerPlacesWithId, getLocations } from '../database'
import { setRatingFromScore } from '../helpers'
import BurgerPlaceAndPoints from './BurgerPlaceAndPoints'
import '../styles.css'

export default function Rating() {
	const [burgerPlaces, setBurgerPlaces] = useState()
	const [locations, setLocations] = useState()
	const [selectedLocation, setSelectedLocation] = useState(null)
	const [filteredBurgerPlaces, setFilteredBurgerPlaces] = useState(null)
	const [isSortingScoreBurger, setIsSortingScoreBurger] = useState(false)
	const [showSortingOptions, setShowSortingOptions] = useState(false)
	const [ratingWidth, setRatingWidth] = useState()

	async function asyncSetBurgerPlaces() {
		setBurgerPlaces(await getBurgerPlacesWithId())
	}

	async function asyncSetLocations() {
		setLocations(await getLocations())
	}

	useEffect(() => {
		asyncSetBurgerPlaces()
		asyncSetLocations()
	}, [])

	useEffect(() => {
		setFilteredBurgerPlaces(
			burgerPlaces
				?.sort(isSortingScoreBurger ? (a, b) => b.burgerPoints - a.burgerPoints : (a, b) => b.points - a.points)
				.filter((place) => selectedLocation === 'Alle lokasjonar' || place.locations.includes(selectedLocation))
				.map((place, index, array) =>
					setRatingFromScore(place, index, array, isSortingScoreBurger, 'points', 'burgerPoints')
				)
		)
		if (selectedLocation) {
			setShowSortingOptions(true)
		}
		// eslint-disable-next-line
	}, [selectedLocation, isSortingScoreBurger])

	useLayoutEffect(() => {
		setRatingWidth(isSortingScoreBurger ? '30%' : '40%')
	}, [isSortingScoreBurger])

	return (
		<>
			<Header />
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '75vh' }}>
				<div className="w-100" style={{ maxWidth: '400px' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: '1rem',
							marginTop: '2rem',
						}}
					>
						<div style={{ flex: 1 }}>
							<h2 className="text-center">Toppliste</h2>
						</div>
						<div className="p-3">
							<OverlayTrigger
								placement="left-start"
								overlay={
									<Tooltip>
										Poenga er gjennomsnittet av alle vurderingane av burgerplassen. For full pakke
										er max poengsum 50, for vurdering av berre burgeren er max poengsum 30.
									</Tooltip>
								}
							>
								<Badge bg="secondary">i</Badge>
							</OverlayTrigger>
						</div>
					</div>
					{showSortingOptions && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginLeft: '12px',
								marginBottom: '10px',
							}}
						>
							<h5>Sortering: </h5>
							<Form.Check
								className="custom-switch"
								inline
								type="switch"
								label="Berre burger"
								checked={isSortingScoreBurger}
								onChange={(e) => setIsSortingScoreBurger(e.target.checked)}
							/>
						</div>
					)}
					<Form.Select
						className="mb-3"
						value={selectedLocation ?? ''}
						onChange={(e) => setSelectedLocation(e.target.value)}
					>
						<option>Velg lokasjon</option>
						<option>Alle lokasjonar</option>
						{locations?.map((location, index) => (
							<option key={index}>{location}</option>
						))}
					</Form.Select>
					{selectedLocation && (
						<div>
							{filteredBurgerPlaces?.map((burgerPlace, index) => (
								<BurgerPlaceAndPoints
									key={index}
									rating={burgerPlace.rating}
									displayName={burgerPlace.displayName}
									points={isSortingScoreBurger ? burgerPlace.burgerPoints : burgerPlace.points}
									url={burgerPlace.url}
									nAssessments={burgerPlace.nAssessments}
									id={burgerPlace.id}
									isSortingScoreBurger={isSortingScoreBurger}
									index={index}
									ratingWidth={ratingWidth}
								/>
							))}
						</div>
					)}
				</div>
			</Container>
		</>
	)
}
