import { getBurgerPlacesWithId } from './database'

export function setRatingFromScore(place, index, places, isSortingScoreBurger, propertyName, burgerPropertyName) {
	let rating = index + 1
	if (index > 0) {
		let prevIndex = index - 1
		while (
			prevIndex >= 0 &&
			(isSortingScoreBurger
				? place?.[burgerPropertyName] === places[prevIndex]?.[burgerPropertyName]
				: place?.[propertyName] === places[prevIndex]?.[propertyName])
		) {
			rating = prevIndex + 1
			prevIndex = prevIndex - 1
		}
	}
	return {
		...place,
		rating,
	}
}

export async function asyncGetDatabaseName(displayName) {
	const allBurgerPlaces = await getBurgerPlacesWithId()
	return allBurgerPlaces
		?.filter((burgerPlace) => burgerPlace.displayName === displayName)
		?.map((burgerPlace) => burgerPlace.id)[0]
}
