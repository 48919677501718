import { db, storage } from './firebase'
import firebase from 'firebase/compat/app'
import { v4 } from 'uuid'

export async function addNewUserToMyBurgersCollection(email, username) {
	const docData = {
		username: username,
		wishlist: [],
	}
	await db.collection('myBurgers').doc(email).set(docData)
}

export async function setNewBurgerPlace(name, displayName, url, locations) {
	const docData = {
		displayName: displayName,
		url: url,
		locations: locations,
		points: 0,
		burgerPoints: 0,
		nAssessments: 0,
	}
	await db.collection('burgerPlaces').doc(name).set(docData)
}

export async function updateBurgerPlacePoints(name, newPoints, newBurgerPoints) {
	const burgerPlace = (await db.collection('burgerPlaces').doc(name).get()).data()
	const docData = {
		nAssessments: burgerPlace.nAssessments + 1,
		points: Math.floor(
			(burgerPlace.points * burgerPlace.nAssessments + newPoints) / (burgerPlace.nAssessments + 1)
		),
		burgerPoints: Math.floor(
			(burgerPlace.burgerPoints * burgerPlace.nAssessments + newBurgerPoints) / (burgerPlace.nAssessments + 1)
		),
	}
	await db.collection('burgerPlaces').doc(name).update(docData)
}

export async function editBurgerPlacePoints(name, newPoints, newBurgerPoints, oldPoints, oldBurgerPoints) {
	const burgerPlace = (await db.collection('burgerPlaces').doc(name).get()).data()
	const docData = {
		nAssessments: burgerPlace.nAssessments,
		points:
			newPoints - oldPoints >= 0
				? Math.floor((newPoints - oldPoints) / burgerPlace.nAssessments) + burgerPlace.points
				: Math.ceil((newPoints - oldPoints) / burgerPlace.nAssessments) + burgerPlace.points,
		burgerPoints:
			newBurgerPoints - oldBurgerPoints >= 0
				? Math.floor((newBurgerPoints - oldBurgerPoints) / burgerPlace.nAssessments) + burgerPlace.burgerPoints
				: Math.ceil((newBurgerPoints - oldBurgerPoints) / burgerPlace.nAssessments) + burgerPlace.burgerPoints,
	}
	await db.collection('burgerPlaces').doc(name).update(docData)
}

export async function deleteBurgerPlacePoints(name, oldPoints, oldBurgerPoints) {
	const burgerPlace = (await db.collection('burgerPlaces').doc(name).get()).data()
	const docData = {
		nAssessments: burgerPlace.nAssessments - 1,
		points:
			burgerPlace.nAssessments === 1
				? 0
				: Math.floor(
						(burgerPlace.points * burgerPlace.nAssessments - oldPoints) / (burgerPlace.nAssessments - 1)
					),
		burgerPoints:
			burgerPlace.nAssessments === 1
				? 0
				: Math.floor(
						(burgerPlace.burgerPoints * burgerPlace.nAssessments - oldBurgerPoints) /
							(burgerPlace.nAssessments - 1)
					),
	}
	await db.collection('burgerPlaces').doc(name).update(docData)
}

export async function deleteBurgerPlace(name) {
	const querySnapshot = await db.collection('myBurgers').get()
	querySnapshot.forEach((doc) => {
		const userHasAssessment = Object.keys(doc.data()).filter((key) => key === name).length > 0
		if (userHasAssessment) {
			deleteMyBurger(doc.id, name)
		}
	})
	await db.collection('burgerPlaces').doc(name).delete()
}

export async function editBurgerPlace(name, newUrl, locations) {
	const docData = {
		url: newUrl,
		locations: locations,
	}
	await db.collection('burgerPlaces').doc(name).update(docData)
}

export async function getAdmins() {
	try {
		const querySnapshot = await db.collection('admins').get()
		const admins = []
		querySnapshot.forEach((doc) => {
			admins.push(doc.data().email)
		})
		admins.sort()
		return admins
	} catch (error) {
		console.log('Error getting admins: ', error)
	}
}

export async function setNewAdmin(email) {
	const docData = {
		email: email,
	}
	await db.collection('admins').doc(email).set(docData)
}

export async function getUsers() {
	try {
		const querySnapshot = await db.collection('myBurgers').get()
		const users = []
		querySnapshot.forEach((doc) => {
			users.push(doc.id)
		})
		return users
	} catch (error) {
		console.log('Error getting users: ', error)
	}
}

export async function getUserNames() {
	try {
		const querySnapshot = await db.collection('myBurgers').get()
		const userNames = []
		querySnapshot.forEach((doc) => {
			userNames.push(doc.data()['username'].toLowerCase())
		})
		return userNames
	} catch (error) {
		console.log('Error getting usernames: ', error)
	}
}

export async function getMyBurgers(email) {
	try {
		const querySnapshot = await db.collection('myBurgers').doc(email).get()
		const keys = Object.keys(querySnapshot.data())
		const fieldsList = keys.filter((key) => key !== 'username' && key !== 'wishlist')
		const myBurgers = fieldsList.map((key) => querySnapshot.data()[key])
		myBurgers.sort((a, b) => b.score - a.score)
		return myBurgers
	} catch (error) {
		console.log('Error getting my burgers: ', error)
	}
}

export async function getMyWishlist(email) {
	try {
		const querySnapshot = await db.collection('myBurgers').doc(email).get()
		const myWishlist = querySnapshot.data()['wishlist']
		return myWishlist
	} catch (error) {
		console.log('Error getting my wishlist: ', error)
	}
}

export async function updateMyWishlist(email, newBurgerplace) {
	const querySnapshot = await db.collection('myBurgers').doc(email).get()
	const currentWishlist = querySnapshot.data()['wishlist']
	const docData = {
		wishlist: [...currentWishlist, newBurgerplace],
	}
	await db.collection('myBurgers').doc(email).update(docData)
}

export async function deleteFromMyWishlist(email, burgerplaceToDelete) {
	const querySnapshot = await db.collection('myBurgers').doc(email).get()
	const currentWishlist = querySnapshot.data()['wishlist']
	const newWishlist = currentWishlist.filter((b) => b !== burgerplaceToDelete)
	const docData = {
		wishlist: newWishlist,
	}
	await db.collection('myBurgers').doc(email).update(docData)
}

export async function getBurgerPlacesWithId() {
	try {
		const querySnapshot = await db.collection('burgerPlaces').orderBy('points', 'desc').get()
		const burgerPlaces = []
		querySnapshot.forEach((doc) => {
			const burgerPlace = {
				id: doc.id,
				...doc.data(),
			}
			burgerPlaces.push(burgerPlace)
		})
		return burgerPlaces
	} catch (error) {
		console.log('Error getting burger places with names: ', error)
	}
}

export async function setNewMyBurger(
	email,
	databaseName,
	displayName,
	meat,
	bun,
	extra,
	fries,
	dip,
	comment,
	score,
	burgerScore,
	imageUrl
) {
	const docData = {
		[databaseName]: {
			displayName: displayName,
			meat: meat,
			bun: bun,
			extra: extra,
			fries: fries,
			dip: dip,
			comment: comment,
			score: score,
			burgerScore: burgerScore,
			url: imageUrl,
		},
	}
	await db.collection('myBurgers').doc(email).update(docData)
	const querySnapshot = await db.collection('myBurgers').doc(email).get()
	const currentWishlist = querySnapshot.data()['wishlist']
	if (currentWishlist.includes(displayName)) {
		const newWishlist = currentWishlist.filter((b) => b !== displayName)
		const docData = {
			wishlist: newWishlist,
		}
		await db.collection('myBurgers').doc(email).update(docData)
	}
}

export async function deleteMyBurger(email, fieldName) {
	await db
		.collection('myBurgers')
		.doc(email)
		.update({
			[fieldName]: firebase.firestore.FieldValue.delete(),
		})
}

export async function getAssessmentsForBurgerPlace(burgerPlaceId) {
	try {
		const querySnapshot = await db.collection('myBurgers').get()
		const assessments = []
		querySnapshot.forEach((doc) => {
			const userHasAssessment = Object.keys(doc.data()).filter((key) => key === burgerPlaceId).length > 0
			if (userHasAssessment) {
				const assessment = {
					user: doc.id,
					username: doc.data().username,
					...doc.data()[burgerPlaceId],
				}
				assessments.push(assessment)
			}
		})
		assessments.sort((a, b) => {
			return b.score - a.score
		})
		return assessments
	} catch (error) {
		console.log('Error getting all assessments: ', error)
	}
}

export async function uploadImageAndGetUrl(file) {
	try {
		const storageRef = storage.ref()
		const fileRef = storageRef.child(file.name)
		// eslint-disable-next-line
		const putFile = await fileRef.put(file)
		return await fileRef.getDownloadURL()
	} catch (error) {
		console.log('Error uploading image and getting url: ', error)
	}
}

export async function deleteImage(url) {
	try {
		const storageRef = storage.refFromURL(url)
		await storageRef.delete()
	} catch (error) {
		console.log('Error deleting image: ', error)
	}
}

export async function getLocations() {
	try {
		const result = (await db.collection('locations').doc('locations').get()).data()
		const locations = result['locations'].sort()
		return locations
	} catch (error) {
		console.log('Error getting locations: ', error)
	}
}

export async function updateLocations(newLocation) {
	const currentLocations = await getLocations()
	const docData = {
		locations: [...currentLocations, newLocation],
	}
	await db.collection('locations').doc('locations').set(docData)
}

export async function getBurgerPlaceLocations(name) {
	try {
		const result = (await db.collection('burgerPlaces').doc(name).get()).data()
		return result['locations']
	} catch (error) {
		console.log('Error getting burger place locations: ', error)
	}
}

export async function getBurgerPlaceUrl(name) {
	try {
		const result = (await db.collection('burgerPlaces').doc(name).get()).data()
		return result['url']
	} catch (error) {
		console.log('Error getting burger place locations: ', error)
	}
}

export async function getFeedback() {
	try {
		const result = await db.collection('feedback').get()
		const feedback = []
		result.forEach((doc) => {
			feedback.push(doc.data())
		})
		return feedback
	} catch (error) {
		console.log('Error getting feedback: ', error)
	}
}

export async function setNewFeedback(title, message, email) {
	const guid = v4()
	const docData = {
		title: title,
		message: message,
		sender: email,
	}
	await db.collection('feedback').doc(guid).set(docData)
}

export async function getStatistics() {
	try {
		const statistics = {}
		const querySnapshotUsers = await db.collection('myBurgers').get()
		let usersAndAssessments = []
		querySnapshotUsers.forEach((doc) => {
			usersAndAssessments.push({
				username: doc.data().username,
				nAssessments: Object.keys(doc.data()).length - 1,
			})
		})
		usersAndAssessments.sort((a, b) => {
			return b.nAssessments - a.nAssessments
		})
		statistics['nUsers'] = querySnapshotUsers.size
		statistics['usersAndAssessments'] = usersAndAssessments
		const querySnapshotBurgerPlaces = await db.collection('burgerPlaces').get()
		let burgerPlaceCount = 0
		let assessmentCount = 0
		let burgerPlacesAndAssessments = []
		querySnapshotBurgerPlaces.forEach((doc) => {
			const burgerPlaceAssessmentCount = doc.data().nAssessments
			if (burgerPlaceAssessmentCount > 0) {
				burgerPlaceCount += 1
				assessmentCount += burgerPlaceAssessmentCount
			}
			burgerPlacesAndAssessments.push({
				burgerPlaceName: doc.data().displayName,
				nAssessments: burgerPlaceAssessmentCount,
			})
		})
		burgerPlacesAndAssessments.sort((a, b) => {
			return b.nAssessments - a.nAssessments
		})
		statistics['nBurgerPlaces'] = querySnapshotBurgerPlaces.size
		statistics['nBurgerPlacesWithAssessments'] = burgerPlaceCount
		statistics['nAssessments'] = assessmentCount
		statistics['burgerPlacesAndAssessments'] = burgerPlacesAndAssessments
		const querySnapshotLocations = await db.collection('locations').doc('locations').get()
		statistics['nLocations'] = querySnapshotLocations.data().locations.length
		return statistics
	} catch (error) {
		console.log('Error getting statistics: ', error)
	}
}

export async function getAssessmentOrWishlistExists(email, burgerPlaceId, displayName) {
	try {
		const querySnapshot = await db.collection('myBurgers').doc(email).get()
		const userHasAssessment = Object.keys(querySnapshot.data()).filter((key) => key === burgerPlaceId).length > 0
		const userHasBurgerplaceOnWishlist = querySnapshot.data()['wishlist'].includes(displayName)
		return userHasAssessment || userHasBurgerplaceOnWishlist
	} catch (error) {
		console.log('Error checking if burger place exists in assessments or wishlist for user: ', error)
	}
}
