import React from 'react'
import { Card, Button, Container } from 'react-bootstrap'
import Header from './Header'
import { useNavigate } from 'react-router-dom'

export default function Menu() {
	const navigate = useNavigate()

	return (
		<>
			<Header />
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '75vh' }}>
				<div className="w-100" style={{ maxWidth: '400px' }}>
					<Card>
						<Card.Body>
							<h2 className="text-center mb-3">Burgervurdering</h2>
							<h5 className="text-center mb-5">Kor finn ein den beste baconcheeseburgeren?</h5>
							<div className="d-grid gap-3">
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={() => navigate('/my-assessments')}
								>
									Mine vurderingar
								</Button>
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={() => navigate('/my-burgerplaces')}
								>
									Mine burgerplassar
								</Button>
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={() => navigate('/all-assessments')}
								>
									Alle vurderingar
								</Button>
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={() => navigate('/rating')}
								>
									Toppliste
								</Button>
							</div>
						</Card.Body>
					</Card>
				</div>
			</Container>
		</>
	)
}
